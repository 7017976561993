import { z } from 'zod'

import type { Absence } from '../absences/types'
import type { Shift } from '../shifts/types'
import type { Signature } from './../agency/types'
import type { Area } from './../areas/types'

export enum accessLevelList {
    GUEST = 'guest',
    USER = 'user',
    ADMIN = 'admin',
    ROOT = 'root',
}

enum accessLevelNumber {
    GUEST = 0,
    USER = 25,
    ADMIN = 50,
    ROOT = 75,
}

export const accessLevelsNum = {
    [accessLevelNumber.GUEST]: accessLevelList.GUEST,
    [accessLevelNumber.USER]: accessLevelList.USER,
    [accessLevelNumber.ADMIN]: accessLevelList.ADMIN,
    [accessLevelNumber.ROOT]: accessLevelList.ROOT,
} as const

// export const accessLevelList = { 0: 'guest', 25: 'user', 50: 'admin', 75: 'root' } as const
// accountManager => accessLevel >= 75

export const transactionTypes = z.enum(['sale', 'acquisition', 'rental'])
export type ITransactionTypes = z.infer<typeof transactionTypes>

const getUserAreas = (userAgency: UserAgency, transactionType: ITransactionTypes | undefined) => {
    if (!transactionType) {
        return [
            ...(userAgency.rentalAreas ?? []),
            ...(userAgency.acquisitionAreas ?? []),
            ...(userAgency.saleAreas ?? []),
        ]
    }

    const areaKey = getAreaKey(transactionType)
    return areaKey ? userAgency[areaKey] ?? [] : undefined
}

export const getAllUsersAreas = (
    users: Account[] | undefined,
    agencyId: string | undefined,
    transactionType: ITransactionTypes
) => {
    if (!users || !agencyId) return []
    return users.flatMap((user) => {
        const areas = getUserAreas(user.agencies[agencyId], transactionType)

        return (
            areas?.map((area) => ({
                ...area,
                owner: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    id: user.id,
                },
            })) ?? []
        )
    })
}

export const getAreaKey = (transactionType: ITransactionTypes | undefined) =>
    transactionType === transactionTypes.enum.rental
        ? 'rentalAreas'
        : transactionType === transactionTypes.enum.acquisition
        ? 'acquisitionAreas'
        : transactionType === transactionTypes.enum.sale
        ? 'saleAreas'
        : undefined

export const getShiftKey = (transactionType: ITransactionTypes | undefined) =>
    transactionType === transactionTypes.enum.rental
        ? 'rentalShifts'
        : transactionType === transactionTypes.enum.acquisition
        ? 'acquisitionShifts'
        : transactionType === transactionTypes.enum.sale
        ? 'saleShifts'
        : undefined

export interface UserAgency {
    id: string
    accessLevel?: accessLevelList
    email: string
    jobTitle: string
    isNegociator: boolean
    saleShifts: Shift[]
    saleAreas: Area[]
    acquisitionShifts: Shift[]
    acquisitionAreas: Area[]
    rentalShifts: Shift[]
    rentalAreas: Area[]
    signature: Signature
    areaColor: string
    absences?: Absence[]
    allocationTransactionEligibilities?: ITransactionTypes[]
}

export interface UserAgencyServer extends Omit<UserAgency, 'accessLevel'> {
    accessLevel: accessLevelList | accessLevelNumber
}

interface Agencies {
    [key: string]: UserAgency
}

export interface Account {
    id: AccountServer['id']
    agencies: Agencies
    firstName: AccountServer['firstName']
    lastName: AccountServer['lastName']
    phone: AccountServer['phone']
    groupIds: AccountServer['groups']
    isRoot: AccountServer['isRoot']
    active: AccountServer['active']
    needsRefreshAfterUpdate: AccountServer['needsRefreshAfterUpdate']
    settings: AccountServer['settings']
}

const salePopupNotificationKeys = [
    'QualificationToConversion',
    'DeliveryToConversion',
    'MandateToConversion',
    'PrequalificationStackToConversion',
    'TerrainToQualificationConversion',
    'TransactionArchiveToDelivery',
    'TransactionToMandate',
    'MandateToSold',
    'DeliveryToArchive',
] as const
export const salePopupNotificationKeysZod = z.enum(salePopupNotificationKeys)
export type ISalePopupNotificationKeys = z.infer<typeof salePopupNotificationKeysZod>

interface UserSettingsServer {
    enableSmsAlerts: boolean
    disableSequenceTypes: string[]
    disableSendEmailSequence: string[]
    permissions: {
        canPrivate: boolean
        canAccessBilling: boolean
    }
    appNotificationUnsubscribe: ISalePopupNotificationKeys[]
}

export interface AccountServer {
    id: string
    agencies: UserAgencyServer[]
    firstName: string
    lastName: string
    phone: string
    email: string
    groups: string[]
    isRoot: boolean
    absences: Absence[]
    active: boolean
    inactiveSince?: Date | undefined
    needsRefreshAfterUpdate: boolean
    settings: Pick<
        UserSettingsServer,
        'disableSequenceTypes' | 'disableSendEmailSequence' | 'permissions' | 'appNotificationUnsubscribe'
    >
}
