import { isDate as isDateDateFns } from 'date-fns'

import type { FormDataContact } from '@/api/contact/types'
import type { ContactDetailType } from '@/api/contactDetails/types'

export const hash = Math.floor(Math.random() * 90000) + 10000

export function hexToRgba(hex: string, alpha = 1): string {
    // Parse the hexadecimal string and convert it to an integer
    const intValue = parseInt(hex.replace('#', ''), 16)

    // Extract the red, green, and blue values from the integer
    const r = (intValue >> 16) & 255
    const g = (intValue >> 8) & 255
    const b = intValue & 255

    // Return the rgba value as a string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export function adjustRGB(color: string, amount: number) {
    return (
        '#' +
        color
            .replace(/^#/, '')
            .replace(/../g, (color: string) =>
                ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
            )
    )
}

export function capitalizeFirstLetterEachWord(string: string | undefined): string {
    if (!string || !string.length) return ''
    return string.toLocaleLowerCase().replace(/(^|\s|[()-_])[a-z\u00E0-\u00FC]/g, (x) => x.toUpperCase())
}

export function capitalizeFirstLetter(string: string | undefined): string {
    if (!string || !string.length) return ''
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

export const isPossibleEmail = (email: string) => {
    return /.+@.+\..+/.test(email)
}

const MS_PER_DAY = 1000 * 60 * 60 * 24
export const getDateDiffDays = (compareDate: Date) => {
    const compareTime = compareDate.getTime()
    const now = new Date().getTime()
    const diffDay = Math.floor(compareTime / MS_PER_DAY) - Math.floor(now / MS_PER_DAY)
    return diffDay
}
export const getContactDetailsMain = (
    type: ContactDetailType,
    contactDetails: FormDataContact['contactDetails']
): string => {
    const list = contactDetails?.filter((detail) => detail.type === type)
    const main =
        list?.find((detail) => detail.isMain)?.data || list?.find((detail) => !detail.isBlacklisted)?.data || ''
    return main
}

export const isValidUrl = (string: string) => {
    try {
        new URL(string)
    } catch (_) {
        return false
    }

    return true
}

export function isRecord(obj: any): obj is Record<string, any> {
    return typeof obj === 'object' && obj !== null
}

export const isString = (value: any): value is string => {
    return value !== undefined && (typeof value === 'string' || value instanceof String)
}

export const isNumber = (value: any): value is number => {
    return value !== undefined && typeof value === 'number'
}

export const isBoolean = (value: any): value is boolean => {
    return value !== undefined && typeof value == 'boolean'
}

export const isDate = (value: any): value is Date => {
    return value !== undefined && isDateDateFns(value)
}
export const isArrayOfStrings = (value: any): value is string[] => {
    return Array.isArray(value) && !!value.length && value.every((item) => isString(item))
}

export const isArrayOfNumbers = (value: any): value is number[] => {
    return Array.isArray(value) && !!value.length && value.every((item) => isNumber(item))
}

export const isPossiblePostalCode = (postalCode: string | number): boolean => {
    // Convert input to string for uniform handling
    const codeString = String(postalCode).trim().replace(/\s/g, '')

    // Check if the string has 3 to 5 digits and consists only of numeric characters
    return /^\d{3,5}$/.test(codeString)
}

export const throttle = (fn: (value: string) => void, wait = 300) => {
    let inThrottle: boolean, lastFn: ReturnType<typeof setTimeout>, lastTime: number

    return function (this: any, ...args: [string]) {
        if (!inThrottle) {
            fn.apply(this, args)
            lastTime = Date.now()
            inThrottle = true
        } else {
            clearTimeout(lastFn)
            lastFn = setTimeout(() => {
                if (Date.now() - lastTime >= wait) {
                    fn.apply(this, args)
                    lastTime = Date.now()
                }
            }, Math.max(wait - (Date.now() - lastTime), 0))
        }
    }
}

export function generatePalette(startHex: string, endHex: string, length: number): string[] {
    // Parse the start and end colors
    const startColor = hexToRgbObject(startHex)
    const endColor = hexToRgbObject(endHex)

    if (length === 1) return [startHex]

    // Calculate the step for each color component
    const step = {
        r: (endColor.r - startColor.r) / (length - 1),
        g: (endColor.g - startColor.g) / (length - 1),
        b: (endColor.b - startColor.b) / (length - 1),
    }

    // Generate the palette
    const palette: string[] = []
    for (let i = 0; i < length; i++) {
        const color = {
            r: Math.round(startColor.r + step.r * i),
            g: Math.round(startColor.g + step.g * i),
            b: Math.round(startColor.b + step.b * i),
        }
        palette.push(rgbToHex(color))
    }

    return palette
}

function hexToRgbObject(hex: string): { r: number; g: number; b: number } {
    // Remove the leading '#' if it exists
    hex = hex.replace(/^#/, '')

    // Parse the hex string into RGB components
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255

    return { r, g, b }
}

function rgbToHex(color: { r: number; g: number; b: number }): string {
    // Convert RGB components to a hex string
    return '#' + ((1 << 24) + (color.r << 16) + (color.g << 8) + color.b).toString(16).slice(1)
}

export const isJsonString = (value: string): boolean => {
    try {
        JSON.parse(value)
        return true
    } catch (e) {
        return false
    }
}
