import { useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import axios from '@/axiosConfig'
import { useAgencyPermissions } from '@/components/navbar/RequireAgencyPermissions'
import { useApp } from '@/contexts/AppContext'

import { adaptServerSaleTag } from '../adapters'
import { tagsKeys } from '../tagsKeys'
import type { ResponseSaleTags, ResponseServerSaleTags } from '../types'
import { tagsTypesEnum } from '../types'

interface IParams {
    agencyId?: string | undefined
    disabled?: boolean
    isSettings?: boolean
}

export default function useGetSaleTags({ agencyId, disabled, isSettings = false }: IParams) {
    const {
        state: { isLoggedIn },
    } = useApp()

    const { isSaleEnabledFct, isTransactionEnabledFct } = useAgencyPermissions()
    const isSaleEnabled = isSaleEnabledFct(true) || isTransactionEnabledFct(true)

    return useQuery<ResponseSaleTags, AxiosError<ErrorResponse>>({
        queryKey: tagsKeys.list(tagsTypesEnum.enum.saleTag, agencyId, isSettings),
        queryFn: () =>
            axios
                .get<ResponseServerSaleTags>(isSettings ? '/sale-tags/settings' : '/sale-tags', {
                    params: {
                        ...(agencyId ? { agencies: agencyId } : {}),
                    },
                })
                .then((res) => ({
                    success: res.data.success,
                    total: res.data.total,
                    tags: res.data.saleTags.map(adaptServerSaleTag),
                    agenciesWhereTagsSharedWithGroup: res.data.agenciesWhereTagsSharedWithGroup,
                })),
        enabled: !(disabled ?? false) && isSaleEnabled && isLoggedIn,
    })
}
